import { ComponentType } from 'react';
import styled from 'styled-components';
import { FacebookMulticolor, GoogleMulticolor } from '@elfsight/icons';
import { DESKTOP, MOBILE, OutlineButton } from '@elfsight-universe/ui-common';

export enum ExternalAccount {
  Facebook = 'facebook',
  Google = 'google',
  Apple = 'apple'
}

type Provider = {
  name: string;
  id: ExternalAccount;
  icon: ComponentType;
};

const providers: Provider[] = [
  {
    name: 'Google',
    id: ExternalAccount.Google,
    icon: GoogleMulticolor
  },
  // {
  //   name: 'Apple',
  //   id: ExternalAccount.Apple,
  //   icon: AppleMulticolor
  // },
  {
    name: 'Facebook',
    id: ExternalAccount.Facebook,
    icon: FacebookMulticolor
  }
];

type AuthExternalAccountButtonsProps = {
  onClick: (providerId: ExternalAccount, providerName: string) => void;
};

export function AuthExternalAccountButtons({
  onClick
}: AuthExternalAccountButtonsProps) {
  return (
    <>
      {providers.map(({ id, name, icon: Icon }) => {
        return (
          <OutlineButton
            key={id}
            large
            fullWidth
            center
            onClick={() => onClick(id, name)}
            leftIcon={Icon}
            iconSize={24}
          >
            Continue with {name}
          </OutlineButton>
        );
      })}
    </>
  );
}

export const AuthExternalAccountButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${DESKTOP} {
    gap: 20px 0;
  }
  ${MOBILE} {
    gap: 16px 0;
  }
`;
