import { toast } from 'react-hot-toast';
import {
  SignInByExternalAccountCallbackEvent,
  SignInByExternalAccountCallbackEventType
} from '@elfsight-universe/service-core-contracts/identity-and-access/sign-in-by-external-account';
import { useWindowOpen, useWindowPostMessage } from '@utils';
import { ContactSupport } from '@modules/_error';
import { ExternalAccount } from './auth-external-account-buttons';
import { useAuth } from './auth-provider';

const { NEXT_PUBLIC_SERVICE_CORE__BASE_URL } = process.env;

const WINDOW_TARGET = 'Authentication';

export const AUTH_ERROR_NOT_VERIFIED = (
  <>
    You normally log in using your email address and password but did not
    confirm the email. To log in with external account, please confirm your
    email first.
  </>
);

export const AUTH_ERROR_WAS_DELETED = (
  <>
    Account has been deleted. If you want to restore it, please{' '}
    <ContactSupport underline>contact support.</ContactSupport>
  </>
);

export const AUTH_ERROR_WAS_FRAUD = (
  <>
    Your account has been suspended due to suspected fraudulent activity. For
    inquiries or resolution, please{' '}
    <ContactSupport underline>contact support.</ContactSupport>
  </>
);

export const AUTH_ERROR_EXTERNAL_ACCOUNT_HAS_NO_EMAIL = (
  <>
    Registration via your Facebook account is not possible. Please sign up using
    Google or email.
  </>
);

export function useAuthByExternalAccount() {
  const openAuthWindow = useWindowOpen();
  const { handleAuth, setEmail } = useAuth();

  useWindowPostMessage<SignInByExternalAccountCallbackEvent>(({ data }) => {
    if (
      data.type === SignInByExternalAccountCallbackEventType.SIGN_IN_SUCCESS
    ) {
      handleAuth(data.isNewSignUp);
      return;
    }

    if (
      data.type === SignInByExternalAccountCallbackEventType.SIGN_IN_FAILURE
    ) {
      if (data.reason === 'NOT_VERIFIED') {
        toast.error(AUTH_ERROR_NOT_VERIFIED);
        setEmail(data.email as string);
      }

      if (data.reason === 'USER_DELETED') {
        toast.error(AUTH_ERROR_WAS_DELETED);
      }

      if (data.reason === 'ACCOUNT_HAS_UNRESOLVED_FRAUD_INCIDENT') {
        toast.error(AUTH_ERROR_WAS_FRAUD);
      }

      if (data.reason === 'EXTERNAL_ACCOUNT_HAS_NO_EMAIL') {
        toast.error(AUTH_ERROR_EXTERNAL_ACCOUNT_HAS_NO_EMAIL);
      }
    }
  });

  return (providerId: ExternalAccount) => {
    openAuthWindow(
      `${NEXT_PUBLIC_SERVICE_CORE__BASE_URL}/login/${providerId}`,
      WINDOW_TARGET
    );
  };
}
