import {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction
} from 'react';
import { Loader } from '@elfsight-universe/ui-common';
import { LayoutPage } from '@components';
import { useRouterQueryReady, useRouterSetQuery } from '@utils';
import { useUser } from '@modules/_app';
import { useOnAuth } from './use-on-auth';

const EMAIL_PARAM = 'email';

export type IAuthContext = {
  handleAuth: (signUp?: boolean) => void;
  authError: string | undefined;
  setAuthError: Dispatch<SetStateAction<string | undefined>>;
  email: string;
  setEmail: (value: string) => void;
  withEmail: boolean;
  toggleWithEmail: () => void;
};

export const AuthContext = createContext<IAuthContext | null>(null);

type AuthProviderProps = PropsWithChildren;

export function AuthProvider({ children }: AuthProviderProps) {
  const { isAuthenticated, isFetched } = useUser();
  const [authError, setAuthError] = useState<string | undefined>();
  const [withEmail, setWithEmail] = useState(false);
  const toggleWithEmail = () => setWithEmail(!withEmail);
  const [email, setEmail] = useState('');
  const setEmailQuery = useRouterSetQuery(EMAIL_PARAM);
  const handleAuth = useOnAuth();

  useRouterQueryReady<string>(EMAIL_PARAM, (email) => {
    setEmail(email);
    setWithEmail(true);
  });

  const changeEmail = (value: string) => {
    setEmail(value);
    setEmailQuery(value);
  };

  useEffect(() => {
    if (isAuthenticated) {
      handleAuth();
    }
  }, [isAuthenticated]);

  if (isAuthenticated || !isFetched) {
    return (
      <LayoutPage>
        <Loader large />
      </LayoutPage>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        handleAuth,
        email,
        setEmail: changeEmail,
        withEmail,
        toggleWithEmail,
        authError,
        setAuthError
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (context === null) {
    throw new Error('`useAuth` must be nested inside an `AuthProvider`.');
  }

  return context;
}
