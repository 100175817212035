import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { SignUpByCredentialsRequest } from '@elfsight-universe/service-core-contracts/identity-and-access';
import { IdentityAndAccessError } from '@elfsight-universe/service-core-contracts/errors';
import { useSignUpByCredentialsMutation } from '@api';
import { catchUnhandledError } from '@modules/_error';
import { useAuth } from '../auth-provider';

export function useSignUpByCredentials() {
  const { setAuthError, handleAuth, email, setEmail } = useAuth();

  const form = useForm({
    mode: 'onBlur',
    resolver: classValidatorResolver(SignUpByCredentialsRequest),
    defaultValues: {
      email,
      password: ''
    }
  });

  const watchEmail = form.watch('email');
  useEffect(() => {
    setEmail(watchEmail);
  }, [watchEmail]);

  const { mutate: signUpByCredentials, isLoading } =
    useSignUpByCredentialsMutation({
      onSuccess: () => {
        setEmail('');
        handleAuth(true);
      },
      onError: (error) => {
        setAuthError(error);

        if (error === IdentityAndAccessError.USER_ALREADY_EXISTS) {
          form.setError('email', {
            type: 'validate',
            message: IdentityAndAccessError.USER_ALREADY_EXISTS
          });
          return;
        }

        catchUnhandledError(error, [
          IdentityAndAccessError.USER_ALREADY_EXISTS
        ]);
      }
    });

  const handleSubmit = form.handleSubmit((data) => signUpByCredentials(data));

  return {
    ...form,
    handleSubmit,
    isLoading
  };
}
