import { useCallback, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import Script from 'next/script';
import { useSignInByGoogleIdToken } from '@api';
import { catchUnhandledError } from '@modules/_error';
import {
  AUTH_ERROR_NOT_VERIFIED,
  AUTH_ERROR_WAS_FRAUD,
  AUTH_ERROR_WAS_DELETED
} from '../use-auth-by-external-account';
import { useAuth } from '../auth-provider';

const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_SERVICE_CORE__GOOGLE_CLIENT_ID;

if (!GOOGLE_CLIENT_ID) {
  throw new Error(
    'It`s expected NEXT_PUBLIC_SERVICE_CORE__GOOGLE_CLIENT_ID env variable to be set.'
  );
}

declare global {
  const google: typeof import('google-one-tap');
}

export function GoogleOneTap() {
  const { handleAuth } = useAuth();

  const { mutate: signInByGoogleIdToken } = useSignInByGoogleIdToken({
    onSuccess: ({ isNewSignUp }) => {
      if (isNewSignUp) {
        handleAuth(true);
        return;
      }

      handleAuth();
    },
    onError: (error) => {
      if (error === 'NOT_VERIFIED') {
        toast.error(AUTH_ERROR_NOT_VERIFIED);
        return;
      }

      if (error === 'USER_DELETED') {
        toast.error(AUTH_ERROR_WAS_DELETED);
        return;
      }

      if (error === 'ACCOUNT_HAS_UNRESOLVED_FRAUD_INCIDENT') {
        toast.error(AUTH_ERROR_WAS_FRAUD);
        return;
      }

      catchUnhandledError(error, [
        'NOT_VERIFIED',
        'USER_DELETED',
        'ACCOUNT_HAS_UNRESOLVED_FRAUD_INCIDENT'
      ]);
    }
  });

  const init = useCallback(() => {
    if (!GOOGLE_CLIENT_ID) return;

    google.accounts.id.initialize({
      client_id: GOOGLE_CLIENT_ID,
      callback: signInByGoogleIdToken,
      cancel_on_tap_outside: false
    });

    google.accounts.id.prompt();
  }, []);

  useEffect(() => {
    if (typeof google !== 'undefined') {
      init();
    }

    return () => {
      if (typeof google !== 'undefined') {
        google.accounts.id.cancel();
      }
    };
  }, []);

  return (
    <Script
      src="https://accounts.google.com/gsi/client"
      onLoad={init}
      async
      defer
    />
  );
}
