import { PropsWithChildren, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { AngleLeft16, ElfsightGlyph } from '@elfsight/icons';
import {
  DESKTOP,
  Icon,
  IconButton,
  MOBILE,
  P
} from '@elfsight-universe/ui-common';
import { LayoutCenter, LayoutCenterProps } from '@components';

type PageLayoutProps = Pick<LayoutCenterProps, 'width'> &
  PropsWithChildren<{
    title?: ReactNode;
    caption?: ReactNode;
    footer?: ReactNode;
    disclaimer?: ReactNode;
    withBackButton?: boolean;
    onBackButtonClick?: () => void;
    withLogo?: boolean;
  }>;

export function AuthLayout({
  children,
  title,
  caption,
  footer,
  withBackButton = false,
  withLogo = false,
  onBackButtonClick,
  ...forwardingProps
}: PageLayoutProps) {
  return (
    <LayoutCenter {...forwardingProps}>
      {title && (
        <ContentHeader>
          {withLogo && (
            <ContentHeaderLogo>
              <Icon component={ElfsightGlyph} size={40} fill="brand" />
            </ContentHeaderLogo>
          )}

          <ContentHeaderTitle>
            {withBackButton && (
              <IconButton
                large
                icon={AngleLeft16}
                onClick={onBackButtonClick}
              />
            )}

            <ContentHeaderTitleText _withBackButton={withBackButton}>
              {title}
            </ContentHeaderTitleText>
          </ContentHeaderTitle>

          {caption && (
            <P mt={20} alignCenter>
              {caption}
            </P>
          )}
        </ContentHeader>
      )}

      <Content>{children}</Content>

      {footer && <Footer>{footer}</Footer>}
    </LayoutCenter>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 300px;
  width: 100%;
`;

const Footer = styled.div`
  align-items: center;
  text-align: center;
  margin-top: 60px;
`;

const ContentHeaderTitle = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.font.title1};
`;

const ContentHeaderLogo = styled.div`
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
`;

const ContentHeader = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  white-space: pre-line;

  ${DESKTOP} {
    margin-bottom: 60px;
  }
  ${MOBILE} {
    margin-bottom: 40px;
  }
`;

const ContentHeaderTitleText = styled.div<{ _withBackButton?: boolean }>`
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ _withBackButton }) =>
    _withBackButton &&
    css`
      padding-right: 44px;
    `}
  flex-grow: 1;
`;
