import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { LayoutPage } from '@components';
import { useSignUpMeta } from '@modules/_app/utils';
import { useWixIntegration } from '@modules/wix-integration/contexts/wix-integration';
import { SignUpMain } from './sign-up';
import { SignInMain } from './sign-in';
import { AuthProvider } from './auth-provider';
import { GoogleOneTap } from './google-one-tap';

export type AuthPageProps = {
  page: 'sign-up' | 'sign-in';
};

export const AuthPage: NextPage<AuthPageProps> = ({ page = 'sign-in' }) => {
  const { query } = useRouter();
  const wixIntegration = useWixIntegration();
  const isAuthFromDemo = query.demo === 'true';

  useSignUpMeta(
    isAuthFromDemo
      ? {
          source: wixIntegration.isWixSession ? 'wix-portal' : 'demo',
          demo_app_alias: query.demoAppAlias as string,
          demo_app_name: query.demoAppName as string,
          demo_widget_pid: query.demoWidgetPid as string,
          wix_instance_id: wixIntegration.isWixSession
            ? wixIntegration.wixInstanceId
            : undefined
        }
      : { source: 'direct' }
  );

  let signUpTitle = 'Create Account';
  let signInTitle = 'Log into Elfsight';

  if (isAuthFromDemo) {
    signUpTitle = 'Your widget has been created.\nSign up to embed the widget.';
    signInTitle = 'Your widget has been created.\nSign in to embed the widget.';
  }

  return (
    <AuthProvider>
      <LayoutPage>
        {
          {
            'sign-up': <SignUpMain title={signUpTitle} />,
            'sign-in': <SignInMain title={signInTitle} />
          }[page]
        }
      </LayoutPage>

      <GoogleOneTap />
    </AuthProvider>
  );
};
