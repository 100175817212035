import { useRouter } from 'next/router';
import { Envelope } from '@elfsight/icons';
import { Link, OutlineButton, P } from '@elfsight-universe/ui-common';
import { AuthLayout } from '../auth-layout';
import {
  AuthExternalAccountButtons,
  AuthExternalAccountButtonsContainer
} from '../auth-external-account-buttons';
import { useAuthByExternalAccount } from '../use-auth-by-external-account';
import { useAuth } from '../auth-provider';
import { SignUpForm } from './sign-up-form';
import { SignUpDisclaimer } from './sign-up-disclaimer';

type SignUpMainProps = {
  title?: string;
  caption?: string;
};

export function SignUpMain({
  title = 'Create Account',
  caption
}: SignUpMainProps) {
  const { withEmail, toggleWithEmail } = useAuth();
  const openAuthWindow = useAuthByExternalAccount();
  const { query } = useRouter();

  const loginLink = (
    <Link color="accent" href={{ pathname: '/login', query }}>
      Log In
    </Link>
  );

  return (
    <>
      <AuthLayout
        title={title}
        caption={caption}
        footer={<P>Have an Elfsight account? {loginLink}</P>}
        withBackButton={withEmail}
        withLogo
        onBackButtonClick={toggleWithEmail}
      >
        {withEmail ? (
          <SignUpForm />
        ) : (
          <AuthExternalAccountButtonsContainer>
            <AuthExternalAccountButtons onClick={openAuthWindow} />
            <OutlineButton
              large
              fullWidth
              center
              leftIcon={Envelope}
              iconSize={24}
              onClick={toggleWithEmail}
            >
              Continue with Email
            </OutlineButton>
          </AuthExternalAccountButtonsContainer>
        )}
      </AuthLayout>

      <SignUpDisclaimer />
    </>
  );
}
