import { Button, Input, Form } from '@elfsight-universe/ui-common';
import { useErrorMessage } from '../use-error-message';
import { useAuth } from '../auth-provider';
import { useSignUpByCredentials } from './use-sign-up-by-credentials';

export function SignUpForm() {
  const { email } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    isLoading
  } = useSignUpByCredentials();

  const emailError = useErrorMessage(errors?.email?.message, email);

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        {...register('email')}
        error={emailError}
        placeholder="Email"
        type="email"
        autoComplete="email"
        spellCheck={false}
        large
      />

      <Input
        {...register('password')}
        error={errors?.password?.message}
        placeholder="Password"
        type="password"
        autoComplete="new-password"
        spellCheck={false}
        large
      />

      <Button
        isLoading={isLoading}
        variation="accentPrimary"
        large
        fullWidth
        center
        type="submit"
      >
        Sign Up
      </Button>
    </Form>
  );
}
