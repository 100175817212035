import styled from 'styled-components';

const TERMS_OF_USE_URL = 'https://elfsight.com/terms-of-service/';
const PRIVACY_POLICY_URL = 'https://elfsight.com/privacy-policy/';

const DISCLAIMER = `By creating an account you agree to our <a href="${TERMS_OF_USE_URL}" target="_blank" rel="noreferrer">Terms of Use</a>
and have read and understood the <a href="${PRIVACY_POLICY_URL}" target="_blank" rel="noreferrer">Privacy Policy</a>`;

export function SignUpDisclaimer() {
  return <Content dangerouslySetInnerHTML={{ __html: DISCLAIMER }} />;
}

const Content = styled.div`
  ${({ theme }) => theme.font.text};
  color: ${({ theme }) => theme.colors.gray50};
  text-align: center;
  margin-top: auto;
  white-space: pre-line;
  margin-bottom: 20px;

  a {
    color: ${({ theme }) => theme.colors.gray50};
  }
`;
