import { useRouter } from 'next/router';
import { Envelope } from '@elfsight/icons';
import { Link, OutlineButton, P } from '@elfsight-universe/ui-common';
import { AuthLayout } from '../auth-layout';
import {
  AuthExternalAccountButtons,
  AuthExternalAccountButtonsContainer
} from '../auth-external-account-buttons';
import { useAuthByExternalAccount } from '../use-auth-by-external-account';
import { useAuth } from '../auth-provider';
import { SignInForm } from './sign-in-form';

export type SignInMainProps = {
  title?: string;
  caption?: string;
};

export function SignInMain({
  title = 'Log into Elfsight',
  caption
}: SignInMainProps) {
  const { withEmail, toggleWithEmail } = useAuth();
  const openAuthWindow = useAuthByExternalAccount();
  const { query } = useRouter();

  const resetPassLink = (
    <Link color="accent" href={{ pathname: '/password-reset', query }}>
      Follow Instructions
    </Link>
  );

  const signupLink = (
    <Link color="accent" href={{ pathname: '/sign-up', query }}>
      Create account
    </Link>
  );

  return (
    <AuthLayout
      title={title}
      caption={caption}
      width={620}
      withBackButton={withEmail}
      withLogo
      onBackButtonClick={toggleWithEmail}
      footer={
        <>
          {withEmail && <P gb>Forgot Password or Email? {resetPassLink}</P>}
          <P>Have no Elfsight account? {signupLink}</P>
        </>
      }
    >
      {withEmail ? (
        <SignInForm />
      ) : (
        <AuthExternalAccountButtonsContainer>
          <AuthExternalAccountButtons onClick={openAuthWindow} />
          <OutlineButton
            large
            fullWidth
            center
            leftIcon={Envelope}
            iconSize={24}
            onClick={toggleWithEmail}
          >
            Continue with Email
          </OutlineButton>
        </AuthExternalAccountButtonsContainer>
      )}
    </AuthLayout>
  );
}
