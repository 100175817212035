import { useRouter } from 'next/router';
import { Link } from '@elfsight-universe/ui-common';
import { IdentityAndAccessError } from '@elfsight-universe/service-core-contracts/errors';
import { ContactSupport } from '@modules/_error';

export const useErrorMessage = (message?: string, email?: string) => {
  const { query } = useRouter();

  const authErrorMessages = {
    [IdentityAndAccessError.USER_DOES_NOT_EXISTS]: (
      <>
        This email was not found.{' '}
        <Link
          href={{ pathname: '/account-recovery', query }}
          color="alert"
          underline
        >
          Forgot email?
        </Link>
      </>
    ),
    [IdentityAndAccessError.PASSWORD_DOES_NOT_MATCH]: (
      <>
        Wrong password.{' '}
        <Link
          href={{ pathname: '/password-reset', query }}
          color="alert"
          underline
        >
          Forgot password?
        </Link>
      </>
    ),
    [IdentityAndAccessError.USER_ALREADY_EXISTS]: (
      <>
        This email already exist.{' '}
        <Link
          href={{ pathname: '/login', query: { ...query, email } }}
          color="alert"
          underline
        >
          Sign In?
        </Link>
      </>
    ),
    [IdentityAndAccessError.USER_DELETED]: (
      <>
        Account has been deleted. If you want to restore it, please{' '}
        <ContactSupport color="alert" underline>
          contact support
        </ContactSupport>
        .
      </>
    ),
    [IdentityAndAccessError.ACCOUNT_HAS_UNRESOLVED_FRAUD_INCIDENT]: (
      <>
        Your account has been suspended due to suspected fraudulent activity.
        For inquiries or resolution, please{' '}
        <ContactSupport color="alert" underline>
          contact support
        </ContactSupport>
        .
      </>
    )
  };

  const hasPresetMessage = message && message in authErrorMessages;

  return hasPresetMessage
    ? authErrorMessages[message as keyof typeof authErrorMessages]
    : message;
};
